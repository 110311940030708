*
{
    margin: 0;
    padding: 0;
}


html,
body
{
    overflow: hidden;
    font-family: 'Helvetica LT UltraCompressed';

}

@font-face {
  font-family: 'Helvetica LT UltraCompressed';
  src:  url('../static/font/font.woff2') format('woff2'),
        url('../static/font/font.woff') format('woff');
}

#level-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: -1;
  transition: backdrop-filter 1s;
}

#level-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99;
  opacity: 1;
  transition: opacity 1s;
  /* z-index: 99999; */

  display: flex;
  justify-content:space-evenly;
  flex-direction: column;
  align-items: center;
}

#button-row {
display: flex;

  flex-direction: row;
  width: 100vw;
  justify-content: space-evenly;
  
}
#gender-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: -1;
  transition: backdrop-filter 1s;
}

#gender-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99;
  opacity: 1;
  transition: opacity 1s;
  /* z-index: 99999; */

  display: flex;
  justify-content:space-evenly;
  flex-direction: column;
  align-items: center;
}

#button-row2 {
  flex-direction: row;
  display: none;
  width: 100vw;
  justify-content: space-evenly;
  
}

.lil-gui {
  font-family: Arial, Helvetica, sans-serif !important;
}

#yes {
  background-color: green;
}

#no {
  background-color: red;
}

#female, #skip {
  /* female pink */
  background-color: #d27ba9 !important;
  box-shadow: 0 2px #5f0133 !important;
}

#male, #next {
  /* male blue */
  background-color: #0059ff !important;
  box-shadow: 0 2px #002c7c !important;
}

#yes, #no, #female, #male, #skip, #next {
  width: 10rem;
  height: 100px;
  font-size: 2rem;
  border-radius: 10px;
  color: white;

}

#yes, #female, #next {
  display: inline-block;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #4CAF50;
  box-shadow: 0 5px #3E8E41;
  transition: all 0.3s;
}

#no, #male, #skip {
  display: inline-block;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #FF1234;
  box-shadow: 0 5px #aa0000;
  transition: all 0.3s;
}

button:hover {
  background-color: #3E8E41;
  transform: translateY(3px);
}

button#yes:active {
  box-shadow: 0 2px #4CAF50 !important;
  transform: translateY(5px);
}
button#no:active {
  box-shadow: 0 2px #FF1234 !important;
  transform: translateY(5px);
}

button#female:active {
 /* match for pink */
  box-shadow: 0 2px #f63ea0 !important;
  transform: translateY(5px);
}
button#male:active {
  /* match for blue */
  box-shadow: 0 2px #0059ff !important;
  transform: translateY(5px);
}

#level-overlay-text, #question {
  color: white;
  font-size: 3.5rem;
  text-align: center;
}

#level-overlay {
  display: none;
}

#question {
  margin: 2rem;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

#questionnaire-screen {
  color: white;
  font-size: 35px;
  overflow-x:hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: start !important;
  letter-spacing: 1.5px;
  padding: 20px;
  width: 90% !important;
  font-family: 'Arial' !important;
}

.submit-button {
  display: inline-block;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-size: 25px;
  font-weight: bold;
  width:50%;
  margin-left:25%;
  text-transform: uppercase;
  color: #fff;
  background-color: #4CAF50;
  box-shadow: 0 5px #3E8E41;
  transition: all 0.3s;
  margin: 50px 25%;
}

.answer-label {
  font-size: 20px;
  padding: 20px 50px;
}

input[type=checkbox] {
  transform: scale(2) ;
  margin-right: 20px;
}

.question {
  padding: 20px 50px;
}

#questionnaire-screen>label {
  padding: 20px;
  font-size: 2.5em;
}

#loading-screen, #tutorial-screen, #questionnaire-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #202020;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  #tutorial-screen{
    z-index: 10000 !important;
  }

  
  #loading-bar {
    width: 0%;
    height: 20px;
    background-color: white;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: width 0.5s;
    transform: scaleX(0.9) translateX(-50%) translateY(-50%);
    transform-origin: left;
  }
  
  #loading-text {
    font-size: 36px;
    color: white;
    left:50%;
transform: translateX(-50%);

    position: relative;
    margin-bottom: 50px;
  }
  
  #loading-percentage {
    margin-top: 20px;
    position: relative;
left:50%;
transform: translateX(-50%);
    font-size: 24px;
    color: white;
  }
  
